@font-face {
    font-family: 'Panton-Bold';
    src: url('./Panton-Bold.woff2') format('woff2'), url('./Panton-Bold.woff') format('woff'), url('./Panton-Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Panton-SemiBold';
    src: url('./Panton-SemiBold.woff2') format('woff2'), url('./Panton-SemiBold.woff') format('woff'), url('./Panton-SemiBold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Panton-Regular';
    src: url('./Panton-Regular.woff2') format('woff2'), url('./Panton-Regular.woff') format('woff'), url('./Panton-Regular.otf') format('opentype');
    font-display: swap;
}