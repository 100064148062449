.section {
    margin: auto;
    margin-top: 6rem;
    width: 532px;
}

.section img {
    display: block;
    width: 120px;
    height: 120px;
    margin: auto;
}
.section h1 {
    width: 532px;
    color: rgb(255, 255, 255);
    font-size: 42px;
    font-family: Panton-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 52px;
}

.section p {
    width: 532px;
    color: rgb(255, 255, 255, 0.8);
    font-size: 16px;
    font-family: Panton-Regular;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
}

@media screen and (max-width: 672px) {
    .section {
        margin-top: 44px;
        width: 80%;
    }

    .section h1 {
        width: 100%;
        color: rgb(255, 255, 255);
        font-size: 28px;
        font-family: Panton-Bold;
        font-weight: bold;
        text-align: center;
        line-height: 36px;
    }

    .section p {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: Panton-Regular;
      font-weight: normal;
      text-align: center;
      line-height: 20px;
      margin: auto;
      margin-bottom: 2rem;
  }
}
