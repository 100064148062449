.btnContainer {
    width: 532px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.btn {
    width: 240px;
    height: 44px;
    border-radius: 4px;
    margin: 0.25rem auto 0.5rem auto;
}

.msg {
    margin-bottom: 3rem;
}

@media screen and (max-width: 672px) {
    .btnContainer {
        width: 100%;
    }
}
