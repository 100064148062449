nav {
  margin: 1.375rem 0;
  padding: 0;
}

.main_nav {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.nav_right {
  display: flex;
  position: relative;
  height: 100%;
  cursor: default;
  margin: 0 1.125rem;
}

.nav_right_txt {
  color: rgb(255, 255, 255);
  font-family: Panton-Regular;
  font-weight: normal;
  line-height: 20px;
}

.nav_left img {
  max-width: 10.5rem;
}

.nav_left a {
  display: flex;
  height: 2rem;
}

.nav_btn {
  width: 85px;
  height: 32px;
  margin: auto 0rem auto 1.75rem;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: 2px solid rgb(146, 209, 244);
  color: rgb(146, 209, 244);
  border-radius: 4px;
  cursor: pointer;
}

.register_txt {
  width: 85px;
  height: 20px;
  color: rgb(146, 209, 244);
  font-size: 14px;
  font-family: Panton-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 20px;
}

.nav_btn span {
  text-decoration: none;
}

.nav_btn:hover {
    background-color: rgb(146, 209, 244);
    transition: ease-in 0.3s;
}

.nav_btn:focus {
  outline: none;
}

.nav_btn:hover span {
    color: black;
    transition: ease-in 0.3s;
}


@media screen and (min-width: 672px) {
  nav {
    margin: 1.375rem 0;
    padding: 0;
  }
  .nav_left img {
    max-width: 10.5rem;
  }
}
@media screen and (min-width: 868px) {
  li.item a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1060px) {
  .main_nav {
    margin: 0 5.375rem;
  }
  .nav-center ul li,
  .nav_right ul li {
    margin: 0 1.125rem;
  }
}

@media screen and (max-width: 672px) {
  .nav_right_txt {
    display: none;
  }
}
