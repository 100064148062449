form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.25rem;
}

form input {
    height: 3rem;
    background: rgb(13, 15, 17, 0.4);
    border-radius: 4px;
    border: none;
    color: rgb(255, 255, 255);
    font-family: Panton-Regular;
    font-size: 1.125rem;
    padding-top: 1rem;
    padding-left: 1rem;
    margin-top: 1rem;
}

form input:focus {
    outline: none !important;
    border: 1px solid rgb(146, 209, 244);
    border-radius: 4px;
}

/* form input.error {
    outline: 1px solid red;
}

form input:invalid {
    outline: 1px solid red;
} */

.errorMsg{
    height: 16px;
    color: rgb(255, 106, 61);
    font-size: 12px;
    font-family: Panton-Regular;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 5px 0 5px 0;
}

.inputError,
.inputError:focus{
    outline: none !important;
    border: 1px solid rgb(255, 106, 61);
    border-radius: 4px;
}

.formGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.formGroup input {
    width: 240px;
}


.floatingLabel {
    display: flex;
    flex-direction: column;
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;   
}

.floatingLabel label {
    margin-top: 1rem;
    height: 16px;
    color: rgb(255, 255, 255, 0.4);
    font-size: 18px;
    font-family: Panton-Regular;
    font-weight: normal;
    line-height: 16px;
    padding: 0 12px;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 26px) scale(1);
    transform-origin: top left;
    transition: all 0.2s ease-out;
}

.floatingLabel:focus-within label {
    transform: translate(7px, 12px) scale(0.75);
    color: rgb(255, 255, 255, 0.6);
    font-size: 16px;
    font-family: Panton-Bold;
    font-weight: bold;
}

.labelActive {
    transform: translate(7px, 12px) scale(0.75) !important;
    color: rgb(255, 255, 255, 0.6) !important;
    font-size: 16px !important;
    font-family: Panton-Bold !important;
    font-weight: bold !important;
}

form input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    background: rgb(13, 15, 17, 0.4);
}

.checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: auto;
    margin: 2.25rem 0 1.5rem 0;
    user-select: none;
    color: white;
    padding-left: 2.25rem;
    padding-top: 0.2rem;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Panton-Regular;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 20px;
}

.bottomCheckbox {
    margin-top: unset;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background: rgb(13, 15, 17, 0.4);
    border: 1px solid #39424c;
    border-radius: 4px;
}

.checkbox:hover input ~ .checkmark {
    border-color: white;
}
.checkbox input:checked ~ .checkmark:after {
    display: block;
}
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #4cded3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.submit {
    width: 532px;
    height: 44px;
    background: rgb(146, 209, 244);
    border-radius: 4px;
    border: none;
    cursor:pointer;
}

.submit:hover {
    background: rgb(60, 170, 231);
    transition: ease-in 0.3s;
}

.cancel {
    width: 532px;
    height: 44px;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: 2px solid rgb(146, 209, 244);
    color: rgb(146, 209, 244);
    border-radius: 4px;
    cursor:pointer;
    margin-top: 0.5rem;
}

@media screen and (max-width: 672px) {

    .formGroup {
        flex-direction: column;
    }

    .formGroup input {
        width: unset;
    }

    .submit {
        width: 100%;
    }

    .cancel {
        width: 100%;
    }
}