.btnContainer {
    width: 532px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.btn {
    width: 240px;
    height: 44px;
    border-radius: 4px;
    margin: 1rem auto;
}

.notification {
    background-color:  rgb(146, 209, 244);
    border-radius: 4px;
    border: 2px solid rgb(146, 209, 244);
    color: #000;
}

@media screen and (max-width: 672px) {
    .btnContainer {
        width: 100%;
    }
}
