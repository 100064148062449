.ForgotPassword {
    text-decoration: none;
    text-align: right;
    width: 110px;
    height: 20px;
    color: rgb(146, 209, 244);
    font-size: 14px;
    font-family: Panton-Regular;
    font-weight: normal;
    line-height: 20px;
    margin: 2.25rem 0 2.25rem 0;
    padding-top: 0.2rem;
}

