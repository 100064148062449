@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap');
@import './fonts/fonts.css';
@import './components/Layout/Layout.css';

body {
    width: 100%;
}

html,
body,
#root {
    display: flex;
    justify-content: center;
}

body,
#root {
    flex-direction: column;
    width: 100%;
}

body {
    background-color: hsl(218, 17%, 18%);
    background-image: url("./assets/bg-a@2x.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

body h1 {
    font-family: 'Panton-Bold';
    color: white;
}

a {
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.75rem;
}

