@import url(https://fonts.googleapis.com/css2?family=Roboto:ital@0;1&display=swap);
nav {
  margin: 1.375rem 0;
  padding: 0;
}

.Nav_main_nav__1DmQD {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
}

.Nav_nav_right__177qx {
  display: flex;
  position: relative;
  height: 100%;
  cursor: default;
  margin: 0 1.125rem;
}

.Nav_nav_right_txt__2UJkR {
  color: rgb(255, 255, 255);
  font-family: Panton-Regular;
  font-weight: normal;
  line-height: 20px;
}

.Nav_nav_left__3IxIk img {
  max-width: 10.5rem;
}

.Nav_nav_left__3IxIk a {
  display: flex;
  height: 2rem;
}

.Nav_nav_btn__28-Es {
  width: 85px;
  height: 32px;
  margin: auto 0rem auto 1.75rem;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: 2px solid rgb(146, 209, 244);
  color: rgb(146, 209, 244);
  border-radius: 4px;
  cursor: pointer;
}

.Nav_register_txt__3-E_h {
  width: 85px;
  height: 20px;
  color: rgb(146, 209, 244);
  font-size: 14px;
  font-family: Panton-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 20px;
}

.Nav_nav_btn__28-Es span {
  text-decoration: none;
}

.Nav_nav_btn__28-Es:hover {
    background-color: rgb(146, 209, 244);
    transition: ease-in 0.3s;
}

.Nav_nav_btn__28-Es:focus {
  outline: none;
}

.Nav_nav_btn__28-Es:hover span {
    color: black;
    transition: ease-in 0.3s;
}


@media screen and (min-width: 672px) {
  nav {
    margin: 1.375rem 0;
    padding: 0;
  }
  .Nav_nav_left__3IxIk img {
    max-width: 10.5rem;
  }
}
@media screen and (min-width: 868px) {
  li.Nav_item__96rLj a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1060px) {
  .Nav_main_nav__1DmQD {
    margin: 0 5.375rem;
  }
  .Nav_nav-center__2nF0H ul li,
  .Nav_nav_right__177qx ul li {
    margin: 0 1.125rem;
  }
}

@media screen and (max-width: 672px) {
  .Nav_nav_right_txt__2UJkR {
    display: none;
  }
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 2.25rem;
}

form input {
    height: 3rem;
    background: rgb(13, 15, 17, 0.4);
    border-radius: 4px;
    border: none;
    color: rgb(255, 255, 255);
    font-family: Panton-Regular;
    font-size: 1.125rem;
    padding-top: 1rem;
    padding-left: 1rem;
    margin-top: 1rem;
}

form input:focus {
    outline: none !important;
    border: 1px solid rgb(146, 209, 244);
    border-radius: 4px;
}

/* form input.error {
    outline: 1px solid red;
}

form input:invalid {
    outline: 1px solid red;
} */

.form_errorMsg__3pXY5{
    height: 16px;
    color: rgb(255, 106, 61);
    font-size: 12px;
    font-family: Panton-Regular;
    font-weight: normal;
    letter-spacing: 0.5px;
    line-height: 16px;
    margin: 5px 0 5px 0;
}

.form_inputError__1dW0R,
.form_inputError__1dW0R:focus{
    outline: none !important;
    border: 1px solid rgb(255, 106, 61);
    border-radius: 4px;
}

.form_formGroup__3m_Qm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.form_formGroup__3m_Qm input {
    width: 240px;
}


.form_floatingLabel__2vKC8 {
    display: flex;
    flex-direction: column;
    position: relative;
}

.form_row__1iw6s {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;   
}

.form_floatingLabel__2vKC8 label {
    margin-top: 1rem;
    height: 16px;
    color: rgb(255, 255, 255, 0.4);
    font-size: 18px;
    font-family: Panton-Regular;
    font-weight: normal;
    line-height: 16px;
    padding: 0 12px;
    pointer-events: none;
    position: absolute;
    -webkit-transform: translate(0, 26px) scale(1);
            transform: translate(0, 26px) scale(1);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    transition: all 0.2s ease-out;
}

.form_floatingLabel__2vKC8:focus-within label {
    -webkit-transform: translate(7px, 12px) scale(0.75);
            transform: translate(7px, 12px) scale(0.75);
    color: rgb(255, 255, 255, 0.6);
    font-size: 16px;
    font-family: Panton-Bold;
    font-weight: bold;
}

.form_labelActive__2xvAt {
    -webkit-transform: translate(7px, 12px) scale(0.75) !important;
            transform: translate(7px, 12px) scale(0.75) !important;
    color: rgb(255, 255, 255, 0.6) !important;
    font-size: 16px !important;
    font-family: Panton-Bold !important;
    font-weight: bold !important;
}

form input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
    background: rgb(13, 15, 17, 0.4);
}

.form_checkbox__2qGV7 {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: auto;
    margin: 2.25rem 0 1.5rem 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: white;
    padding-left: 2.25rem;
    padding-top: 0.2rem;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-family: Panton-Regular;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 20px;
}

.form_bottomCheckbox__3j-HV {
    margin-top: unset;
}

.form_checkmark__3mgTd {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background: rgb(13, 15, 17, 0.4);
    border: 1px solid #39424c;
    border-radius: 4px;
}

.form_checkbox__2qGV7:hover input ~ .form_checkmark__3mgTd {
    border-color: white;
}
.form_checkbox__2qGV7 input:checked ~ .form_checkmark__3mgTd:after {
    display: block;
}
.form_checkmark__3mgTd:after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #4cded3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form_submit__3Y-XZ {
    width: 532px;
    height: 44px;
    background: rgb(146, 209, 244);
    border-radius: 4px;
    border: none;
    cursor:pointer;
}

.form_submit__3Y-XZ:hover {
    background: rgb(60, 170, 231);
    transition: ease-in 0.3s;
}

.form_cancel__2bufW {
    width: 532px;
    height: 44px;
    background-color: Transparent;
    background-repeat:no-repeat;
    border: 2px solid rgb(146, 209, 244);
    color: rgb(146, 209, 244);
    border-radius: 4px;
    cursor:pointer;
    margin-top: 0.5rem;
}

@media screen and (max-width: 672px) {

    .form_formGroup__3m_Qm {
        flex-direction: column;
    }

    .form_formGroup__3m_Qm input {
        width: unset;
    }

    .form_submit__3Y-XZ {
        width: 100%;
    }

    .form_cancel__2bufW {
        width: 100%;
    }
}
.signIn_ForgotPassword__ULcpN {
    text-decoration: none;
    text-align: right;
    width: 110px;
    height: 20px;
    color: rgb(146, 209, 244);
    font-size: 14px;
    font-family: Panton-Regular;
    font-weight: normal;
    line-height: 20px;
    margin: 2.25rem 0 2.25rem 0;
    padding-top: 0.2rem;
}


.confirmEmail_btnContainer__2hquO {
    width: 532px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.confirmEmail_btn__1FlxK {
    width: 240px;
    height: 44px;
    border-radius: 4px;
    margin: 1rem auto;
}

.confirmEmail_notification__2Oj-j {
    background-color:  rgb(146, 209, 244);
    border-radius: 4px;
    border: 2px solid rgb(146, 209, 244);
    color: #000;
}

@media screen and (max-width: 672px) {
    .confirmEmail_btnContainer__2hquO {
        width: 100%;
    }
}

.ForgotPassword_btn__kZCmY {
    margin-top: 2rem;
}
.NewPassword_btn__3sPB4 {
    border-radius: 4px;
    margin: 1rem auto;
}

.AccountNotFound_btnContainer__3cWh2 {
    width: 532px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.AccountNotFound_btn__2UFb8 {
    width: 240px;
    height: 44px;
    border-radius: 4px;
    margin: 0.25rem auto 0.5rem auto;
}

.AccountNotFound_msg__1D479 {
    margin-bottom: 3rem;
}

@media screen and (max-width: 672px) {
    .AccountNotFound_btnContainer__3cWh2 {
        width: 100%;
    }
}

@font-face {
    font-family: 'Panton-Bold';
    src: url(/static/media/Panton-Bold.f57c981e.woff2) format('woff2'), url(/static/media/Panton-Bold.8aee1060.woff) format('woff'), url(/static/media/Panton-Bold.7501ed5d.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Panton-SemiBold';
    src: url(/static/media/Panton-SemiBold.aca473d6.woff2) format('woff2'), url(/static/media/Panton-SemiBold.e5cd63d9.woff) format('woff'), url(/static/media/Panton-SemiBold.d5d27b6f.otf) format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'Panton-Regular';
    src: url(/static/media/Panton-Regular.b03674cd.woff2) format('woff2'), url(/static/media/Panton-Regular.97fc15e9.woff) format('woff'), url(/static/media/Panton-Regular.6f4842be.otf) format('opentype');
    font-display: swap;
}
.section {
    margin: auto;
    margin-top: 6rem;
    width: 532px;
}

.section img {
    display: block;
    width: 120px;
    height: 120px;
    margin: auto;
}
.section h1 {
    width: 532px;
    color: rgb(255, 255, 255);
    font-size: 42px;
    font-family: Panton-Bold;
    font-weight: bold;
    text-align: center;
    line-height: 52px;
}

.section p {
    width: 532px;
    color: rgb(255, 255, 255, 0.8);
    font-size: 16px;
    font-family: Panton-Regular;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
}

@media screen and (max-width: 672px) {
    .section {
        margin-top: 44px;
        width: 80%;
    }

    .section h1 {
        width: 100%;
        color: rgb(255, 255, 255);
        font-size: 28px;
        font-family: Panton-Bold;
        font-weight: bold;
        text-align: center;
        line-height: 36px;
    }

    .section p {
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: Panton-Regular;
      font-weight: normal;
      text-align: center;
      line-height: 20px;
      margin: auto;
      margin-bottom: 2rem;
  }
}

body {
    width: 100%;
}

html,
body,
#root {
    display: flex;
    justify-content: center;
}

body,
#root {
    flex-direction: column;
    width: 100%;
}

body {
    background-color: hsl(218, 17%, 18%);
    background-image: url(/static/media/bg-a@2x.1c71fa80.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

body h1 {
    font-family: 'Panton-Bold';
    color: white;
}

a {
    color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.875rem;
    padding: 0.75rem;
}


